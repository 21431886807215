import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import {
	useActions,
	usePublisherActions,
	useCampaignsActions,
	useAdvertiserActions,
	useUserActions,
	useRoleActions,
	useSettingsActions,
	useLogActions,
	useLearningActions,
	useCreatorActions,
	useReportActions,
	useNotificationActions,
	useNewsActions,
	useTasksActions,
} from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { fetchData } from '../utils/helpers/navigationHelper'
import {
	CLIENT_VERSION,
	MAX_PROGRESS_BEFORE_DATA,
	MIN_LOADING_TIME,
} from '../config'
import moment from 'moment'
import { getVersionAction } from '../state/action-creators'
import {
	AnimatedFirstLoading,
	FirstLoading,
	Loading,
} from '../assets/svg/loading'
import { getFiltersAction } from '../state/action-creators/reportActions'
import {
	defaultAggregatedFilters,
	defaultInvalidFilters,
} from '../utils/helpers/reportHelperFuncs'
import {
	fetchInChunks,
	fetchInChunksUntilEnd,
} from '../utils/helpers/fetchInChunks'

export const LoadingComponent = ({
	setLoadingFromComponent,
	loadingFromComponent,
	setUnderMaintenance,
	onPriorityDataDone,
}: {
	setLoadingFromComponent: any
	loadingFromComponent: boolean
	setUnderMaintenance: any
	onPriorityDataDone?: (done: boolean) => void
}) => {
	const [isDownloading, setIsDownloading] = useState(false)
	const [isBackgroundLoading, setIsBackgroundLoading] = useState(false)
	const [firstDownloadDone, setFirstDownloadDone] = useState(false)
	const [progress, setProgress] = useState(0)
	const [dataReady, setDataReady] = useState(false)
	const [targetProgress, setTargetProgress] = useState(0)
	const [fetchLogs, setFetchLogs] = useState<
		{ name: string; duration: number }[]
	>([])

	const {
		getAppsAction,
		getPasswordsAction,
		getAvatars,
		getDashboardAction,
		getAccountsAction,
		getAppsIcons,
		getp360aggregated,
		getSalesPlanAppsAction,
		// getHandshakeAction,
		getPermissions,
		// getCompanySettings,
		getAllCompanies,
	} = useActions()

	const userEmail = useTypedSelector((state) => state.login.user?.email)

	const { getPublisherWishList, getPublisherAction } = usePublisherActions()
	const { getCampaignHistoryAction, getCampaignAction } = useCampaignsActions()
	const { getAdvertiserAction } = useAdvertiserActions()
	const { getLoggedInUser, logOutAction, usersAction, updateUsersLmsStatus } =
		useUserActions()
	const { fetchRoles } = useRoleActions()
	const { getNewsAction } = useNewsActions()
	const { getSettingsAction } = useSettingsActions()
	const { getLogsAction } = useLogActions()
	const { getLearningAction } = useLearningActions()
	const { getCreatorsAction } = useCreatorActions()
	const [tasksComplete, setTasksComplete] = useState(false)
	const [totalTasks, setTotalTasks] = useState(0)
	const [fakeCap, setFakeCap] = useState(97)
	const { loadAllReportsAction, getNetworkAction, getInvalidAction } =
		useReportActions()

	const [hasSeenAnimatedLoading, setHasSeenAnimatedLoading] = useState(
		Boolean(localStorage.getItem('hasSeenAnimatedLoading')),
	)
	const { getUserNotifications } = useNotificationActions()
	const { getTasksAction } = useTasksActions()
	const navigate = useNavigate()

	const MIN_LOADING_TIME_MS = 300
	const WAIT_FOR_BACKGROUND_TASKS = 10000 //10 seconds

	useEffect(() => {
		const intervalId = setInterval(() => {
			setProgress((oldValue) => {
				if (oldValue < fakeCap) {
					return Math.min(oldValue + 2, fakeCap)
				}
				return oldValue
			})
		}, 50)

		return () => {
			clearInterval(intervalId)
		}
		return () => clearInterval(intervalId)
	}, [fakeCap])

	useEffect(() => {
		if (tasksComplete && targetProgress >= 100) {
			setFirstDownloadDone(true)
		}
	}, [tasksComplete, targetProgress])

	// Helper function to log fetch duration
	const logFetch = async (fetchFunc: any, name: string) => {
		const startTime = performance.now()
		await fetchFunc()
		const endTime = performance.now()
		const duration = endTime - startTime

		setFetchLogs((prevLogs) => [...prevLogs, { name, duration }])
	}

	// Check if data needs to be reloaded
	const lastFetchTime = localStorage.getItem('lastFetchTime')
	const dateIsOneDayOld =
		!lastFetchTime || moment().diff(moment(lastFetchTime), 'hours') >= 24

	// Map of fetch functions
	type FetchGroup = {
		priority: Array<() => Promise<void>>
		background: Array<() => Promise<void>>
	}

	const mapFetching: Record<string, FetchGroup> = {
		first_download: {
			priority: [
				() => logFetch(getLoggedInUser, 'getLoggedInUser'),
				() => logFetch(getNewsAction, 'getNewsActions'),
				() => logFetch(getSettingsAction, 'getSettingsAction'),
				() => logFetch(usersAction, 'usersAction'),
				() => logFetch(getPermissions, 'getPermissions'),
				() => logFetch(getAppsAction, 'getAppsAction'),
				() => logFetch(fetchRoles, 'fetchRoles'),
				() => logFetch(getAllCompanies, 'getAllCompanies'),
				// () => logFetch(getTasksAction, 'getTasksAction'),
				() =>
					logFetch(
						() => getUserNotifications(userEmail),
						'getUserNotifications',
					),
			],
			background: [
				() => fetchInChunksUntilEnd(getAdvertiserAction, 50),
				() => fetchInChunksUntilEnd(getPublisherAction, 50),
				() => fetchInChunksUntilEnd(getCreatorsAction, 50),
				() => fetchInChunksUntilEnd(getCampaignAction, 50),
				() => logFetch(getLogsAction, 'getLogsAction'),
				() => logFetch(getPasswordsAction, 'getPasswordsAction'),
				() => logFetch(getAccountsAction, 'getAccountsAction'),
				() => logFetch(getAvatars, 'getAvatars'),
				() => logFetch(getPublisherWishList, 'getPublisherWishList'),
				() => logFetch(getAppsIcons, 'getAppsIcons'),
				() => logFetch(getLearningAction, 'getLearningAction'),
				() => logFetch(getDashboardAction, 'getDashboardAction'),
				() => logFetch(getSalesPlanAppsAction, 'getSalesPlanAppsAction'),
				() => logFetch(updateUsersLmsStatus, 'updateUsersLmsStatus'),
			],
		},
		news: {
			priority: [() => logFetch(getNewsAction, 'getNewsActions')],
			background: [
				() =>
					logFetch(
						() => getUserNotifications(userEmail),
						'getUserNotifications',
					),
				() => logFetch(getAvatars, 'getAvatars'),
			],
		},
		campaigns: {
			priority: [
				() =>
					fetchInChunks(getCampaignAction, 50, 'campaigns', setIsDownloading), // Priority
				// () => logFetch(getCampaignAction, 'getCampaignAction'), // Priority
			],
			background: [
				() => logFetch(getAppsIcons, 'getAppsIcons'),
				() => logFetch(getAppsAction, 'getAppsAction'),
			],
		},
		advertisers: {
			priority: [
				() =>
					fetchInChunks(
						getAdvertiserAction,
						50,
						'advertisers',
						setIsDownloading,
					), // Priority
			],
			background: [() => logFetch(getAppsAction, 'getAppsAction')],
		},
		publishers: {
			priority: [
				() =>
					fetchInChunks(getPublisherAction, 50, 'publishers', setIsDownloading), // Priority
			],
			background: [
				// () =>
				// 	fetchInChunks(getCreatorsAction, 50, 'creators', setIsDownloading),
			],
		},
		creators: {
			priority: [
				() =>
					fetchInChunks(getCreatorsAction, 50, 'creators', setIsDownloading),
			], // Priority
			background: [
				// () =>
				// 	fetchInChunks(getPublisherAction, 50, 'publishers', setIsDownloading),
			],
		},
		companysettings: {
			priority: [
				() =>
					fetchInChunks(getPublisherAction, 50, 'publishers', setIsDownloading), // Priority
				// () => logFetch(getPublisherAction, 'getPublisherAction'), // Priority
			],
			background: [
				() => logFetch(getAdvertiserAction, 'getAdvertiserAction'),
				() => logFetch(getAppsAction, 'getAppsAction'),
				() => fetchInChunksUntilEnd(getCreatorsAction, 50),
			],
		},
		users: {
			priority: [
				() => logFetch(usersAction, 'usersAction'), // Priority
			],
			background: [
				() => logFetch(fetchRoles, 'fetchRoles'),
				// () => logFetch(updateUsersLmsStatus, 'updateUsersLmsStatus'),
			],
		},
		learning: {
			priority: [
				() => logFetch(getLearningAction, 'getLearningAction'), // Priority
			],
			background: [],
		},
		passwords: {
			priority: [
				() => logFetch(getPasswordsAction, 'getPasswordsAction'), // Priority
			],
			background: [],
		},
		apps: {
			priority: [
				() => logFetch(getAppsAction, 'getAppsAction'), // Priority
			],
			background: [],
		},
		settings: {
			priority: [
				() => logFetch(getSettingsAction, 'getSettingsAction'), // Priority
			],
			background: [
				() => logFetch(fetchRoles, 'fetchRoles'),
				// Add logFetch when using getCompanySettings if required
			],
		},
		logs: {
			priority: [
				() => logFetch(getLogsAction, 'getLogsAction'), // Priority
			],
			background: [],
		},
		publisherwishlist: {
			priority: [
				() => logFetch(getPublisherWishList, 'getPublisherWishList'), // Priority
			],
			background: [],
		},
		appsflyeraccounts: {
			priority: [
				() => logFetch(getAccountsAction, 'getAccountsAction'), // Priority
			],
			background: [],
		},
		network: {
			priority: [
				() => logFetch(getNetworkAction, 'getNetworkAction'), // Priority
			],
			background: [
				() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
			],
		},
		aggregated: {
			priority: [
				() =>
					logFetch(
						getFiltersAction.bind(null, defaultAggregatedFilters),
						'getFiltersAction',
					), // Priority
			],
			background: [
				() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
			],
		},
		invalid: {
			priority: [
				() =>
					logFetch(
						getInvalidAction.bind(null, defaultInvalidFilters),
						'getInvalidAction',
					), // Priority
			],
			background: [
				() => logFetch(loadAllReportsAction(userEmail), 'loadAllReportsAction'),
			],
		},
		notifications: {
			priority: [
				() =>
					logFetch(
						() => getUserNotifications(userEmail),
						'getUserNotifications',
					), // Priority
			],
			background: [],
		},
		interval: {
			priority: [
				() => fetchInChunksUntilEnd(getAdvertiserAction, 50),
				() => fetchInChunksUntilEnd(getPublisherAction, 50),
				() => fetchInChunksUntilEnd(getCreatorsAction, 50),
			],
			background: [],
		},
	}

	// Essential data fetching with progress tracking
	useEffect(() => {
		const fetchEssentialData = async () => {
			try {
				console.time('Essential Data Loading')
				console.time('Essential Background Data Loading')
				setIsDownloading(true)

				const startTime = Date.now()
				let completed = 0

				const total = mapFetching.first_download.priority.length

				const updateProgress = (done: number) => {
					const fraction = (done / total) * 100
					setTargetProgress((prevTarget) => {
						// clamp so it never decreases
						return fraction > prevTarget ? fraction : prevTarget
					})
				}

				// 1) priority tasks
				const promisesPriority = mapFetching.first_download.priority.map((fn) =>
					fn().then(() => {
						completed++
						updateProgress(completed)
					}),
				)

				const promisesBg = mapFetching.first_download.background.map((fn) =>
					fn().then(() => {
						completed++
						updateProgress(completed)
					}),
				)
				await Promise.all(promisesPriority)

				const elapsed = Date.now() - startTime
				if (elapsed < MIN_LOADING_TIME_MS) {
					await new Promise((r) => setTimeout(r, MIN_LOADING_TIME_MS - elapsed))
				}

				// Wait for background tasks but force-complete if they take too long (e.g. after 3 seconds).
				await Promise.race([
					Promise.all(promisesBg),
					new Promise((resolve) =>
						setTimeout(resolve, WAIT_FOR_BACKGROUND_TASKS),
					),
				])

				setTargetProgress(100)
				setFakeCap(100)
				setTasksComplete(true)

				console.timeEnd('Essential Data Loading')
				console.timeEnd('Essential Background Data Loading')

				localStorage.setItem('hasSeenAnimatedLoading', 'true')
				setHasSeenAnimatedLoading(true)
			} catch (error) {
				console.error('Error loading essential data:', error)
			} finally {
				setIsDownloading(false)
				onPriorityDataDone?.(true)
			}
		}

		// If data is older than one day, reload it
		if (!hasSeenAnimatedLoading && dateIsOneDayOld) {
			fetchEssentialData()
		} else {
			setFirstDownloadDone(true) // Skip loading if data is recent

			// If skipping because data is still fresh,
			// still let the app know
			onPriorityDataDone?.(true)
		}
	}, [])

	// Background data fetching for campaignHistory and network, only if data is over a day old
	useEffect(() => {
		if (firstDownloadDone && dateIsOneDayOld && userEmail) {
			setIsBackgroundLoading(true)
			const fetchBackgroundData = async () => {
				try {
					await Promise.all([
						logFetch(getCampaignHistoryAction, 'getCampaignHistoryAction'),
						logFetch(getNetworkAction, 'getNetworkAction'),
						logFetch(
							() => loadAllReportsAction(userEmail),
							'loadAllReportsAction',
						),
						logFetch(getp360aggregated, 'p360aggregated'),
					])
				} catch (error) {
					console.error('Error loading background data:', error)
				} finally {
					setIsBackgroundLoading(false)
				}
			}

			fetchBackgroundData()
		}
	}, [firstDownloadDone, dateIsOneDayOld])

	const lastBasicFetchTime = useRef(Date.now())
	useEffect(() => {
		const fiveMinutes = 5 * 60 * 1000 // 5 minutes in ms
		const excludeDownloadPaths = ['/advertisers', '/publishers', '/creators']
		const interval = setInterval(async () => {
			const path = window.location.pathname
			let skipDownload = false
			for (const el of excludeDownloadPaths) {
				if (path.startsWith(el)) {
					skipDownload = true
					break
				}
			}
			if (
				Date.now() - lastBasicFetchTime.current >= fiveMinutes &&
				mapFetching.interval.priority.length > 0 &&
				!skipDownload
			) {
				console.time('downloading-long in interval')
				await Promise.allSettled(
					mapFetching.interval.priority.map((fetchFunc) => fetchFunc()),
				)
				console.timeEnd('downloading-long in interval')
				lastBasicFetchTime.current = Date.now()
			}
			if (skipDownload) {
				lastBasicFetchTime.current = Date.now()
			}
		}, 5000)

		return () => clearInterval(interval)
	}, [mapFetching.interval.priority])

	// Route-specific data fetching on path change
	useEffect(() => {
		const keyToDownload = convertPathToMapKey(window.location.pathname)
		if (keyToDownload && mapFetching[keyToDownload]) {
			const fetchRouteData = async () => {
				setIsDownloading(true)
				console.time('downloading')
				console.time('downloading-long')

				const priorityFetchFunc = mapFetching[keyToDownload].priority
				const backgroundFetchFuncs = mapFetching[keyToDownload].background

				try {
					// Execute the priority fetch first
					if (priorityFetchFunc) {
						await Promise.all(priorityFetchFunc.map((fetchFunc) => fetchFunc()))
						setIsDownloading(false) // Stop loading after the priority fetch finishes
						console.timeEnd('downloading')
					}

					// Execute other fetch functions in the background without affecting loading state
					if (backgroundFetchFuncs.length > 0) {
						await Promise.allSettled(
							backgroundFetchFuncs.map((fetchFunc) => fetchFunc()),
						)
						console.timeEnd('downloading-long')
					}
				} catch (error) {
					console.error(`Error fetching data for ${keyToDownload}:`, error)
					setIsDownloading(false)
					console.timeEnd('downloading')
				}
			}
			fetchRouteData()
		}
		const checkVersion = async () => {
			const result = await getVersionAction(CLIENT_VERSION)
			if (
				result !== undefined &&
				(result.data.successful !== true ||
					result.data.payload.version === false)
			) {
				sessionStorage.clear()
				window.location.reload()
			} else if (result?.data.payload.under_maintenance === true) {
				setUnderMaintenance(true)
				navigate('/maintenance')
			}
		}
		checkVersion()
	}, [window.location.pathname])

	// // Render fetch logs after loading
	// useEffect(() => {
	// 	if (!isDownloading && !isBackgroundLoading) {
	// 		// console.table(fetchLogs)
	// 		setProgress(100) // Ensure it reaches 100% when done
	// 	}
	// }, [isDownloading, isBackgroundLoading])

	useEffect(() => {
		setTotalTasks(
			mapFetching.first_download.priority.length +
				mapFetching.first_download.background.length,
		)
	}, [])

	return firstDownloadDone ? (
		<>
			{(isDownloading || loadingFromComponent) && <Loading loading={true} />}
			{isBackgroundLoading && <Loading loading={false} />}
			<Outlet />
		</>
	) : (
		<AnimatedFirstLoading progress={progress} />
	)
}
const convertPathToMapKey = (string: string) => {
	let result = ''

	const mappings = {
		'/news': 'news',
		'/campaigns': 'campaigns',
		'/advertisers': 'advertisers',
		'/users': 'users',
		'/publishers': 'publishers',
		'/creators': 'creators',
		'/passwords': 'passwords',
		'/documents': 'learning',
		'/settings': 'settings',
		'/apps': 'apps',
		'/logs': 'logs',
		'/publisherwishlist': 'publisherwishlist',
		'/appsflyeraccounts': 'appsflyeraccounts',
		'/companySettings': 'companysettings',
		'/network': 'network',
		'/aggregated': 'aggregated',
		'/invalid': 'invalid',
		'/notifications': 'notifications',
	}
	const mustMatch = ['/advertisers', '/publishers', '/creators', '/campaigns']
	for (const key in mappings) {
		if (mustMatch.includes(key)) {
			if (string === key) {
				result = mappings[key as keyof typeof mappings]
				break
			}
		} else {
			if (string.startsWith(key)) {
				result = mappings[key as keyof typeof mappings]
				break
			}
		}
	}
	// console.log('result = ', result)
	return result
}
