import React, { useEffect } from 'react'
import { useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ActionType } from '../state/action-types'
import { useLogActions } from '../hooks/useActions'

interface AuthProps {
	setFirstLoadingDone: React.Dispatch<React.SetStateAction<boolean>>
}

const Auth: React.FC<AuthProps> = ({ setFirstLoadingDone }) => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const token = query.get('token') || ''

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { insertLog } = useLogActions()

	// 1) Grab the theme
	const theme = useTheme()

	useEffect(() => {
		// 2) Keep the existing iframe handshake logic:
		const iframe = document.createElement('iframe')
		iframe.style.display = 'none'

		const isProd = !window.location.hostname.includes('localhost')
		const isStage = window.location.hostname.includes('stage.varys.io')
		const url = `http${isProd ? 's' : ''}://${
			isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:4000'
		}/api/authorize?redirect=${window.location.origin}&token=${token}`

		iframe.src = url
		document.body.appendChild(iframe)

		const messageListener = async (event: MessageEvent) => {
			if (event.data?.hello) return
			if (event.data?.token) {
				try {
					const parsedData = JSON.parse(atob(event.data.token))

					dispatch({
						type: ActionType.LOGIN,
						payload: parsedData.login,
					})

					setFirstLoadingDone(true)

					insertLog({
						email: parsedData.login.user.email,
						change: 'Login',
						object: {},
					})

					navigate('/news')
				} catch (err) {
					console.error('Auth handshake error: ', err)
				}
			}
		}

		window.addEventListener('message', messageListener)
		return () => {
			window.removeEventListener('message', messageListener)
			document.body.removeChild(iframe)
		}
	}, [token, dispatch, insertLog, setFirstLoadingDone, navigate])

	// 3) Render a full-screen blank DIV with your theme's background color
	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				backgroundColor: theme.colors.base.white,
			}}
		/>
	)
}

export default Auth
