import {
	Box,
	LinearProgress,
	Paper,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { styled as styledMUI } from '@mui/material/styles'
import { Logo } from '../../pages/components/reusableComponents'
import logo from '../varys-logo-colored.png'
import { keyframes } from '@emotion/react'

import './loading.css'
import CircularProgress, {
	circularProgressClasses,
} from '@mui/material/CircularProgress'

export const Loading = (props: { loading: boolean; report?: boolean }) => {
	if (!props.loading) {
		return <></>
	}
	const pageHeight = document.documentElement.scrollHeight
	const isNews = window.location.pathname === '/news'
	return (
		<Paper
			style={{
				position: 'absolute',
				top: '0%',
				right: '0%',
				width: 'calc(100vw)',
				height: isNews ? '100vh' : pageHeight,
				backdropFilter: 'blur(6px)',
				zIndex: '99',
				backgroundColor: 'transparent',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className='lds-ring'
				style={{ position: 'absolute', marginTop: '-5%' }}
			>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const FirstLoading = (props: { progress: number; style?: any }) => {
	const theme = useTheme()
	// useEffect(() => {
	//  console.log(props.progress)
	// }, [props.progress])

	return (
		<>
			<ThemeProvider theme={theme}>
				<Paper
					style={{
						position: 'fixed',
						top: '0%',
						width: '100%',
						height: '100%',
						zIndex: '12',
						backgroundColor: theme.colors.base.white,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						left: '0%',
						flexDirection: 'column',
						overflow: 'hidden',
						...props.style,
					}}
				>
					<Paper
						style={{
							position: 'absolute',
							top: '0%',
							width: '100%',
							height: '100%',
							zIndex: '12',
							backgroundColor: 'transparent',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							left: '0%',
							flexDirection: 'column',
							overflow: 'hidden',
						}}
					></Paper>
					<div
						style={{
							display: 'flex',
							position: 'relative',
							// transform: 'scale(0.8)'
							marginTop: -160,
						}}
					>
						<img height={300} src={logo} alt='Varys' />
					</div>
					<Box
						sx={{
							position: 'relative',
							width: '30%',
							height: '60px',
							marginTop: '40px',
						}}
					>
						<LinearProgress
							style={{
								zIndex: '13',
								position: 'relative',
								top: -2,
								height: 9,
								borderRadius: '10px',
								backgroundColor: theme.colors.base.white,
							}}
							variant='determinate'
							value={Math.min(props.progress, 100)} // Ensure it doesn't exceed 100%
							color='primary'
							classes={{}}
						/>
						<Box
							height={'60px'}
							sx={{
								position: 'relative',
								width: '101%',
								height: '11px',
								// marginTop: '100px',
								marginLeft: '-0.5%',
								marginTop: -1.5,
								borderRadius: '10px',
								background: `linear-gradient(90deg, ${theme.colors.base.green200} 0%, ${theme.colors.base.green400} 100%)`,
							}}
						></Box>
					</Box>
					{
						<span style={{ color: theme.colors.text.titles, zIndex: '13' }}>
							Daily download might take longer
						</span>
					}
				</Paper>
			</ThemeProvider>
		</>
	)
}

export const SmallLoading: React.FC<{ style?: React.CSSProperties }> = ({
	style = {},
}) => {
	return (
		<Paper
			elevation={0}
			style={{
				position: 'absolute',
				top: '50%',
				left: '2%',
				transform: 'translate(-50%, -50%)',
				zIndex: '11',
				backgroundColor: 'transparent',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			}}
		>
			<div
				className='lds-ring-small'
				style={{ position: 'absolute', marginTop: '-5%' }}
			>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const CustomLoader: React.FC<{ isLoading: boolean }> = ({
	isLoading,
}) => {
	if (!isLoading) return null

	return (
		<Paper
			elevation={0}
			style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				zIndex: 1000,
				transform: 'translate(-50%, -50%)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '50%',
				padding: '1rem',
				backgroundColor: 'transparent',
			}}
		>
			<div className='lds-ring-small'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Paper>
	)
}

export const LoadingOverlay = (props: { loading: boolean }) => {
	const [showLoader, setShowLoader] = useState(props.loading)

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (props.loading) {
			setShowLoader(true)
			timer = setTimeout(() => {
				setShowLoader(false)
			}, 1500)
		} else {
			setShowLoader(false)
		}
		return () => clearTimeout(timer)
	}, [props.loading])

	if (!showLoader) {
		return null
	}

	return (
		<div
			style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 1200,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
			}}
		>
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

const logoAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-120%) rotate(0deg) scale(0.7);
  }
  70% {
    opacity: 1;
    transform: translateX(0%) rotate(360deg) scale(1.25);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) rotate(360deg) scale(1);
  }
  `

const waveColorShift = (baseColors: any, mode: 'light' | 'dark') => {
	const colors =
		mode === 'dark'
			? {
					c1: baseColors.green200,
					c2: baseColors.green300,
					c3: baseColors.yellow300,
					c4: baseColors.green400,
			  }
			: {
					c1: baseColors.green400,
					c2: baseColors.green500,
					c3: baseColors.yellow300,
					c4: baseColors.green600 || baseColors.green500,
			  }

	return keyframes`
		0%   { color: ${colors.c1}; }
		25%  { color: ${colors.c2}; }
		50%  { color: ${colors.c3}; }
		75%  { color: ${colors.c4}; }
		100% { color: ${colors.c1}; }
	`
}

type AnimatedFirstLoadingProps = {
	progress: number
	style?: React.CSSProperties
}

export const AnimatedFirstLoading: React.FC<AnimatedFirstLoadingProps> = (
	props,
) => {
	const theme = useTheme()

	return (
		<ThemeProvider theme={theme}>
			<Paper
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 9999,
					backgroundColor:
						theme.palette?.mode === 'dark'
							? theme.colors.base.white
							: theme.colors.base.white,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					...props.style,
				}}
			>
				<Box
					sx={{
						animation: `${logoAnimation} 2.5s ease-in-out forwards`,
					}}
				>
					<img
						src={logo}
						alt='Loading Logo'
						style={{
							width: '220px',
							height: 'auto',
						}}
					/>
				</Box>

				<Box
					sx={{
						marginTop: '50px',
						width: '40%',
						minWidth: '280px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<LinearProgress
						variant='determinate'
						value={Math.min(props.progress, 100)}
						sx={{
							width: '100%',
							height: 9,
							borderRadius: '10px',

							backgroundColor:
								theme.palette.mode === 'dark'
									? theme.colors.base.grey700
									: theme.colors.base.grey200,

							'& .MuiLinearProgress-bar': {
								// Bar color: green->yellow gradient
								background: `linear-gradient(90deg,
									${theme.colors.base.green300},
									${theme.colors.base.green500})`,
							},
						}}
					/>

					<AnimatedMessage
						message={`Varys is getting things ready for you...`}
					/>
				</Box>
			</Paper>
		</ThemeProvider>
	)
}
const AnimatedMessage = ({ message }: { message: string }) => {
	const theme = useTheme()
	const baseColors = theme.colors.base
	const mode = theme.palette.mode === 'dark' ? 'dark' : 'light'

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '1px',
				justifyContent: 'center',
				flexWrap: 'wrap',
			}}
		>
			{message.split('').map((char, index) => (
				<Box
					key={index}
					component='span'
					sx={{
						marginTop: '1rem',
						animation: `${waveColorShift(
							baseColors,
							mode,
						)} 2s ease-in-out infinite`,
						animationDelay: `${index * 0.02}s`,
						animationFillMode: 'forwards',
						fontWeight: 'bold',
						fontSize: theme.font?.size?.body || 20,
						fontFamily: theme.font?.family,
					}}
				>
					{char === ' ' ? '\u00A0' : char}
				</Box>
			))}
		</Box>
	)
}

const StyledCircularProgress = styledMUI(CircularProgress)(({ theme }) => ({
	animationDuration: '850ms',
	[`& .${circularProgressClasses.circle}`]: {
		strokeLinecap: 'round',
	},
	color: theme.colors.base.grey600,
}))

export const CustomSearchSpinner: React.FC<{
	size?: number
	thickness?: number
}> = ({ size = 16, thickness = 7 }) => {
	const theme = useTheme()
	return (
		<StyledCircularProgress
			variant='indeterminate'
			disableShrink
			size={size}
			thickness={thickness}
			sx={{ color: theme.colors.base.grey600 }}
		/>
	)
}
